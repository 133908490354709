@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --pc-: #e30917;
  --sc-: #27358b;
  --fc-: #4c4c4c;
}
body {
  margin: 0;
  font-family: "Roboto", "Noto Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Noto Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;

  font-style: normal;
  font-variation-settings: "wdth" 100;
}
